import React, { useCallback, useEffect, useState } from 'react';
import './SuperDialog.stylesheet.scss';
import { uniq } from 'lodash';
import Modal from 'react-modal';
import firebase from '../Firebase';
import IconButton from '@material-ui/core/IconButton';
import StarOutlineRoundedIcon from '@material-ui/icons/StarOutlineRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';

// Configuration
Modal.setAppElement("#root");

const SuperDialog = ({ exames, isFavorite, exame, isOpen, onClose, onRemember, onToggleFavorite }) => {
    // States
    const [exameEnunciadoUrl, setExameEnunciadoUrl] = useState("");
    const [loadingEnunciadoUrl, setLoadingEnunciadoUrl] = useState(false);
    const [exameGuiaUrl, setExameGuiaUrl] = useState("");
    const [loadingGuiaUrl, setLoadingGuiaUrl] = useState(false);

    // Methods
    const onOpen = useCallback(() => {
        /**
         * Prepare the downlooad links */ 
        // Show loading
        setExameGuiaUrl(""); // reset
        setExameEnunciadoUrl(""); // reset
        setLoadingEnunciadoUrl(true);
        setLoadingGuiaUrl(true);

        // Grab the selected exame from db.
        let _filtered;
        if (exame.isEnsinoSecundario) {
            _filtered = exames.filter(e => (
                (e.ensino === exame.ensino) && 
                (e.classeOuInstituto === exame.classeOuInstituto) &&
                (e.disciplina === exame.disciplina) &&
                (e.ano === exame.ano) &&
                (e.epoca === exame.epoca)
            ));
        
        } else if (exame.isEnsinoSuperior) {
            _filtered = exames.filter(e => (
                (e.ensino === exame.ensino) && 
                (e.classeOuInstituto === exame.classeOuInstituto) &&
                (e.disciplina === exame.disciplina) &&
                (e.ano === exame.ano)
            ));
        }
        
        // Check whether the selected exame has enunciado or guia or both.
        // Get a list containing "enunciado" or "guia" or both.
        const _enGuAvailabilityRaw = _filtered.map(e => e.enunciadoOuGuia);
        const _enGuAvailability = uniq(_enGuAvailabilityRaw);
        
        // Firebase Storage init.
        const storageRef = firebase.storage().ref();

        // Generate the download links from the cloud storage object.
        if (_enGuAvailability.includes("Enunciado")) {
            // Generate the appropriate enunciado link of the exame
            const enunciadoRef = storageRef.child(exame.storedEnunciadoPath);
            enunciadoRef.getDownloadURL()
                .then(enunciadoUrl => {
                    // Got the url of the object!
                    setExameEnunciadoUrl(enunciadoUrl);
                    setLoadingEnunciadoUrl(false);
                })
                .catch(() => {
                    // This should not catch, because of the extra layer of checks with database.
                    // But if it happens, this means that we were not able find this object with the given path in cloud storage.
                    // Possible resolutions include:
                    //  - Check path? 
                    //  - Probably got deleted? Note: Make sure to update your database accordingly if that's the case.
                    setExameEnunciadoUrl("");
                    setLoadingEnunciadoUrl(false);
                });

        } else {
            // Did not find enunciado of this exame in our database.
            // So no need to grab the url from the cloud storage. Ofc.
            // - Just disable the enunciado button from this dialog.
            setExameEnunciadoUrl("");
            setLoadingEnunciadoUrl(false);
        }


        if (_enGuAvailability.includes("Guia")) {
            // Generate the appropriate guia link of the exame
            const guiaRef = storageRef.child(exame.storedGuiaPath);
            guiaRef.getDownloadURL()
                .then(guiaUrl => {
                    // Got the url of the object!
                    setExameGuiaUrl(guiaUrl);
                    setLoadingGuiaUrl(false);
                })
                .catch(() => {
                    // This should not catch, because of the extra layer of checks with database.
                    // But if it happens, this means that we were not able find this object with the given path in cloud storage.
                    // Possible resolutions include:
                    //  - Check path? 
                    //  - Probably got deleted? Note: Make sure to update your database accordingly if that's the case.
                    setExameGuiaUrl("");
                    setLoadingGuiaUrl(false);
                });

        } else {
            // Did not find guia of this exame in our database.
            // So no need to grab the url from the cloud storage. Ofc.
            // - Just disable the guia button from this dialog.
            setExameGuiaUrl("");
            setLoadingGuiaUrl(false);
        }
    }, [exames, exame]);

    const handleOnClose = () => {
        // Reset values to default
        setExameEnunciadoUrl("");
        setExameGuiaUrl("");
        setLoadingEnunciadoUrl(false);
        setLoadingGuiaUrl(false);

        if (onClose) {
            onClose();
        }
    };


    return (
        <Modal isOpen={isOpen}
            style={{
                overlay: {
                    background: "rgba(0, 0, 0, 0.75)"
                }
            }}
            onAfterOpen={onOpen}
            className="h-100 d-flex justify-content-center align-items-center"
            contentLabel="Baixar exame"
            onRequestClose={handleOnClose}
            shouldCloseOnEsc={true}>

            <div className="e-modal p-5 e-modal-anim">
                <div className="row mb-2 align-items-center justify-content-between">
                    <div className="col-auto">
                        <button onClick={onClose} className="fw-bold e-back py-1 rounded-pill">
                            <i className="fas fa-arrow-circle-left"></i> Voltar
                        </button>
                    </div>
                    <div className="col-auto">
                        <IconButton aria-label="favorite" onClick={() => onToggleFavorite(exame, !isFavorite)}>
                            { isFavorite ? (
                                <StarRoundedIcon className="text-yellow animate__animated animate__rubberBand animate__fast" fontSize="large" />
                            ) : (
                                <StarOutlineRoundedIcon className="text-yellow" fontSize="large" /> 
                            ) }
                        </IconButton>
                    </div>
                </div>

                <h2>Exame selecionado</h2>
                <h5 className="text-primary">
                    {exame.disciplina} • {exame.ano}
                </h5>
                <p className="text-muted mb-0">
                    {exame.classeOuInstituto} {`${exame.epoca ? '– ' + exame.epoca : ''}`}
                </p>
                <p className="text-muted mt-0">
                    {exame.ensino}
                </p>

                <div className="row mt-4">
                    <div className="col-auto">
                        <a onClick={() => onRemember(exame)}
                            tabIndex="0"
                            href={exameEnunciadoUrl} 
                            target="_blank" 
                            className={`btn e-download-enunciado ${!exameEnunciadoUrl ? 'disabled' : ''}`}>

                            { loadingEnunciadoUrl && (
                                <span className="spinner-border spinner-border-sm" role="status" style={{ marginRight: 8 }} />
                            )}
                            <span>Enunciado</span>
                        </a>
                    </div>

                    <div className="col-auto">
                        <a onClick={() => onRemember(exame)}
                            tabIndex="0"
                            href={exameGuiaUrl}
                            target="_blank"
                            className={`btn e-download-guia ${!exameGuiaUrl ? 'disabled' : ''}`}>

                            { loadingGuiaUrl && (
                                <span className="spinner-border spinner-border-sm" role="status" style={{ marginRight: 8 }} />
                            )}
                            <span>Guia</span>
                        </a>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default SuperDialog;