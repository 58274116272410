import React, { useState, useCallback } from 'react';
import PrivacyIcon from '../../images/privacy.svg';
import toast from 'react-hot-toast';
import Spinner from '../../components/Spinner';
import { Redirect } from 'react-router';
import { useAuth } from '../../hooks/useAuth';

const Login = () => {

    /**
     * Hooks
     */
    const auth = useAuth();

    /**
     * States
     */
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMeEnabled, toggleRememberMe] = useState(false);
    const [loading, setLoading] = useState(false);

    /**
     * Methods
     */
    const onAuthenticate = useCallback(async (e) => {
        e.preventDefault(); // prevent the form to redirect submission
        setLoading(true);

        auth.signin(email, password, () => {
            toast.error("Credentials do not match");
            setLoading(false);
        });

    }, [auth, email, password]);


    return auth.user ? (
        <Redirect to="/console/manage" />

    ) : (
        <div className="container">
            <img src={PrivacyIcon} width="64" alt="Exames Moçambique - Security icon" />
            <div className="row justify-content-between">
                <div className="col-auto">
                    <h2 className="text-black mt-4 mb-0 text-left">Hey there.</h2>
                    <h2 className="text-muted mb-4 text-left">Your credentials please?</h2>
                </div>
                <div className="col-auto">
                    <Spinner enabled={loading} />
                </div>
            </div>

            <form onSubmit={onAuthenticate}>
                <input value={email} disabled={loading} onChange={(e) => setEmail(e.currentTarget.value)} type="email" className="e-input w-100 mb-3" placeholder="Admin email" />
                <input value={password} disabled={loading} onChange={(e) => setPassword(e.currentTarget.value)} type="password" className="e-input w-100 mb-3" placeholder="Admin password" />

                <div className="row align-items-center justify-content-between gy-3">
                    <div className="col-auto">
                        <div className="form-check">
                            <input id="rememberMe" disabled={loading} checked={rememberMeEnabled} className="form-check-input" onChange={(e) => toggleRememberMe(!rememberMeEnabled)} type="checkbox" />
                            <label className="form-check-label fw-bold" htmlFor="rememberMe">
                                Remember me
                            </label>
                        </div>
                    </div>

                    <div className="col-auto">
                        <button type="submit" disabled={loading} className="btn btn-lg e-btn-console fw-bold">
                            <i className="fas fa-sign-in-alt"></i> Authenticate
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Login;
