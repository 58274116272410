import React, { useState } from 'react';
import UsIcon from '../../images/us.svg';
import Logo from '../../images/logo.png';
import Nav from '../../components/Nav';
import { Link } from 'react-router-dom';
import { ContribuintesDatabase } from '../../databases/ContribuintesDatabase';


const AboutUs = () => {

    const [contribuintes] = useState(ContribuintesDatabase.data);

    return (
        <div>
            <Nav />

            <section className="uk-section e-green-gradient">
                <div className="uk-container uk-container-small uk-light uk-padding-small">
                    <img width="72" src={UsIcon} alt="Sobre Nós" />
                    <h1 className="uk-margin-remove-bottom uk-text-bold uk-margin-small-top">Sobre Nós</h1>
                    <h3 className="uk-margin-remove-top">Creditos, Apoios e Contribuições</h3>
                    <p>
                        Encontra aqui mais informações sobre o Exames Moçambique, quem apoia-nos e licenças de atribuição de alguns produtos ou serviços utilizados no nosso aplicativo e website. <br />
                        <br />
                        Assim, agradecemos a todos que directa ou indirectamente apoiaram na melhoria deste projecto. <span className="fw-bold">Muito obrigado! Thank you!</span>
                    </p>
                </div>
            </section>


            <section className="uk-section">
                <div className="uk-container uk-container-small uk-padding-small">
                    <div>
                        <h3>Introdução</h3>
                        <p>
                            Exames Moçambique é um projecto sem fins lucrativos apoiado por vários estudantes e docentes, com o objectivo de facilitar aos estudantes nacionais na busca pelos exames feitos em anos anteriores, quer seja do ensino Secundário (com exames da 10a e 12a Classe), quer seja do ensino Superior (exames de admissão), para ajudá-los na preparação para os exames que irão decorrer nos próximos anos.<br />
                            Este é totalmente gratuíto e pode ser acessado através do nosso aplicativo para dispositivos Android <a className="text-decoration-none" href="https://play.google.com/store/apps/details?id=com.kishan.exames&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">disponível no Google PlayStore pelo nome de `Exames Nacionais – Moçambique`</a>, assim como através do nosso aplicativo <Link className="text-decoration-none" to="/">web acessado via o nosso website</Link>.
                        </p>

                        <p>
                            Se não fosse pela contribuição de toda a comunidade estudantil e muitos outros, este projecto não seria possível. Deste modo, creditamos nesta página à todos que contribuiram de alguma forma ào Exames Moçambique. São softwares de terceiros e/ou serviços prestados por tais, que podem estar incorporados em porções no nosso aplicativo e website, assim como os links e liçencas de atribuição quando necessários.
                        </p>
                        <p>
                            Nota: Exames Moçambique, não elabora nenhum dos exames disponíveis nos nossos aplicativos. Somente os publicamos.
                            Todos os exames do Ensino Secundário Geral, são elaborados pelo Instituto Nacional de Exames, Certificação e Equivalência (CNE) e os do Ensino Superior, elaborados pela instituição correspondente.
                        </p>
                    </div>

                    <div className="mt-5">
                        <h5>Apoiantes e contribuintes</h5>
                        <table className="uk-table uk-table-small uk-table-divider uk-margin-remove-top">
                            <thead>
                                <tr>
                                    <th className="uk-table-expand"></th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    contribuintes.map(contribuinte => (
                                        <tr key={contribuinte.name}>
                                            <td>
                                                {contribuinte.link ? (
                                                    <a target="_blank" href={contribuinte.link}>
                                                        { contribuinte.name}
                                                    </a>
                                                ) : (
                                                    contribuinte.name
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="mt-5">
                        <h5>Tipografia</h5>
                        <p>
                            A tipografia utilizada por nós é tão única, de fácil leitura e futurística quanto nós.<br />
                            <a href="http://indestructibletype.com/Jost.html" className="fw-bold text-decoration-none text-reset" style={{ fontFamily: "jost" }}>Jost<span className="text-danger">*</span></a> – Uma verdadeira obra prima moderna criada pelo <span title="Designer de Tipo-de-letra"><i>typeface designer</i></span> <a className="text-decoration-none" href="https://ewonrael.github.io/">Owen Earl</a>.
                        </p>
                    </div>


                    <div className="mt-5">
                        <h5>Iconografia</h5>
                        <p>
                            Para facilitar e guiá-lo enquanto navega o aplicativo ou pelo website, Exames Moçambique utiliza imagens visuais e símbolos fáceis de se interpretar, proprietários (e alguns colaborados pela <a className="text-decoration-none" href="http://www.freepik.com/">Freepik</a> e <a className="text-decoration-none" href="https://www.flaticon.com/authors/icongeek26">Icongeek26</a>), por exemplo, na ilustração das disciplinas ou de algumas acções que poderá executar.<br />
                            O Logotipo <img src={Logo} width="32" alt="Logo" /> é marca única de <strong>Exames Moçambique</strong>.
                        </p>
                    </div>

                    <hr className="e-divider my-5" />

                    <div>
                        <h5>Contactos</h5>
                        <p>
                            Alguma dúvida sobre o nosso projecto? Fale para nós.<br />
                            Tens algum exame (enunciado ou guia) que ainda não temos no nosso app? Partilhe conosco e vamos juntos ajudar a outros colegas! <br />
                        </p>
                        <div className="mb-0 mt-2">
                            <span className="fw-bold text-secondary"><i className="far fa-envelope-open"></i> Email</span>: <a href="mailto:kishan_jadav@hotmail.com?subject=Exames Moçambique - Web">kishan_jadav@hotmail.com</a>
                        </div>
                        <div>
                            <span className="fw-bold text-secondary"><i className="far fa-comment"></i> Facebook</span>: <a href="https://facebook.com/exames.moz">@exames.moz</a>
                        </div>
                    </div>

                    <div className="mt-5">
                        <div>
                            <small>
                                © 2017-2021 Exames Moçambique e seus colaboradores individuais.
                            </small>
                        </div>
                        <div>
                            <small>
                                Criado por <a style={{ color: "#564A27", fontWeight: 800 }} className=" text-decoration-none" href="https://kishanjadav.com">Kishan Jadav</a>
                            </small>
                        </div>
                    </div>


                </div>
            </section>
        </div>
    );
};

export default AboutUs;
