class Exame {
    /**
     * Create a new instance of Exame.
     * 
     * @param {String} ensino ensino of the exame
     * @param {String} classeOuInstituto classe or instituto of the exame
     * @param {String} disciplina disciplina of the exame
     * @param {String} ano ano of the exame
     * @param {String} epoca epoca of the exame
     */
    constructor({ensino, classeOuInstituto, disciplina, ano, epoca}) {
        this.ano               = ano;
        this.epoca             = epoca;
        this.ensino            = ensino;
        this.disciplina        = disciplina;
        this.classeOuInstituto = classeOuInstituto;
    }

    // Methods
    toObject() {
        return {
            ano: this.ano,
            epoca: this.epoca,
            ensino: this.ensino,
            disciplina: this.disciplina,
            classeOuInstituto: this.classeOuInstituto,
        };
    }


    // Getters
    /**
     * Returns whether or not the current exame is of ensino secundario.
     * 
     * @see isEnsinoSuperior() to check whether or not the current exame is of ensino superior.
     */
    get isEnsinoSecundario() {
        return this.ensino === "Ensino Secundario";
    }

    /**
     * Returns whether or not the current exame is of ensino superior.
     * 
     * @see isEnsinoSecundario() to check whether or not the current exame is of ensino secundario.
     */
    get isEnsinoSuperior() {
        return this.ensino === "Ensino Superior";
    }


    /**
     * Get the path of where the enunciado of this exame is/could be located in Cloud Storage.
     * @return {String} the enunciado path on cloud storage.
     */
    get storedEnunciadoPath() {
        if (this.isEnsinoSecundario) {
            // (Enunciado) Ensino Secundario
            const enunciadoFileName = `${this.ano}-${this.epoca}.pdf`;
            return `${this.disciplina}/${this.classeOuInstituto}/${enunciadoFileName}`;
        
        } else if (this.isEnsinoSuperior) {
            // (Enunciado) Ensino Superior
            const enunciadoFileName = `${this.classeOuInstituto}-${this.disciplina}-${this.ano}.pdf`;
            return `Universidade/${this.classeOuInstituto}/${this.disciplina}/${enunciadoFileName}`;
        }
    }


    /**
     * Get the path of where the guia of this exame is/could be located in Cloud Storage.
     * @return {String} the guia path on cloud storage.
     */
    get storedGuiaPath() {
        if (this.isEnsinoSecundario) {
            // (Guia) Ensino Secundario
            const guiaFileName = `${this.ano}-${this.epoca}.pdf`;
            return `Guias/${this.disciplina}/${this.classeOuInstituto}/${guiaFileName}`;
        
        } else if (this.isEnsinoSuperior) {
            // (Guia) Ensino Superior
            const guiaFileName = `${this.classeOuInstituto}-${this.disciplina}-${this.ano}-Guia.pdf`;
            return `Universidade/Guias/${this.classeOuInstituto}/${this.disciplina}/${guiaFileName}`;
        }
    }



}

export default Exame;