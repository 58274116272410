export const CitationsDatabase = {
    citations: [{
        quote: "A educação tem raízes amargas, mas os seus frutos são doces.",
        author: "Aristóteles",

    }, {
        quote: "É melhor você tentar algo, vê-lo não funcionar e aprender com isso, do que não fazer nada.",
        author: "Mark Zuckerberg",

    }, {
        quote: "Acredite que você pode, assim você já está no meio do caminho.",
        author: "Theodore Roosevelt"
    
    }, {
        quote: "Tudo sempre parece mais claro quando olhamos para trás.",
        author: "Stephen Hawking"

    }, {
        quote: "Saber como pensar torna a pessoa muito mais capaz do que aquele que apenas sabe o que deve pensar.",
        author: "Neil deGrasse Tyson"
    }, {
        quote: "Não há nada que não se possa aprender, basta a curiosidade e a inteligência.",
        author: "Vitorio Furusho"
    }, {
        quote: "Educai as crianças e não será preciso punir os homens.",
        author: "Pitágoras"

    }, {
        quote: "A educação visa melhorar a natureza do homem o que nem sempre é aceite pelo interessado.",
        author: "Carlos Dummond de Andrade"

    }, {
        quote: "A educação é a arma mais poderosa que você pode usar para mudar o mundo.",
        author: "Nelson Mandela"

    }, {
        quote: "O ser humano é aquilo que a educação faz dele.",
        author: "Immanuel Kant"

    }, {
        quote: "Se a educação sozinha não transforma a sociedade, sem ela tampouco a sociedade muda.",
        author: "Paulo Freire"

    }, {
        quote: "Feliz aquele que transfere o que sabe e aprende o que ensina.",
        author: "Cora Coralina"

    }, {
        quote: "Se queres prever o futuro, estuda o passado.",
        author: "Confúcio"

    }, {
        quote: "A boa educação é moeda de ouro. Em toda a parte tem valor.",
        author: "Padre Antônio Vieira"

    }, {
        quote: "Educação nunca foi despesa. Sempre foi investimento com retorno garantido.",
        author: "Sir Arthur Lewis"

    }, {
        quote: "A educação é a chave para abrir outros direitos humanos.",
        author: "Katarina Tomasevski"

    }, {
        quote: "O conhecimento lhe dará a oportunidade de fazer a diferença.",
        author: "Claire Fagin"
        
    }, {
        quote: "Estudar é polir a pedra preciosa; cultivando o espírito, purificamo-lo.",
        author: "Confúcio"
        
    }, {
        quote: "A juventude é a época de se estudar a sabedoria; a velhice é a época de a praticar.",
        author: "Jean-Jacques Rousseau"
        
    }, {
        quote: "Não há só um método para estudar as coisas.",
        author: "Aristóteles"
        
    }, {
        quote: "Ninguém fica doido de tanto estudar! É mais fácil ficar doido de tanto ser burro.",
        author: "André Azevedo da Fonseca"
        
    }, {
        quote: "Aprender é a única coisa de que a mente nunca se cansa, nunca tem medo e nunca se arrepende.",
        author: "Leonardo da Vinci"
    }, {
        quote: "O que sabemos é uma gota; o que ignoramos é um oceano.",
        author: "Isaac Newton"
    }]
};