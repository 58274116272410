import React from 'react';
import AdsIcon from '../../images/ads.svg';
import SettingsButtonImage from '../../images/settings.png';
import Nav from '../../components/Nav';
import { Link } from 'react-router-dom';


const Ads = () => {
    return (
        <div>
            <Nav />

            <section className="uk-section e-blue-gradient">
                <div className="uk-container uk-container-small uk-light uk-padding-small">
                    <img style={{ border: "3px solid white", borderRadius: "12px" }} width="72" className="text-light" src={AdsIcon} alt="Ads" />
                    <h1 className="uk-margin-remove-bottom uk-text-bold uk-margin-small-top">Anúncios Publicitários</h1>
                    <p>Também conhecido como: "Propagandas", "Ads" ou ainda "Advertisements".</p>
                </div>
            </section>

            <section className="uk-section">
                <div className="uk-container uk-container-small uk-padding-small">
                    <div>
                        <h3>Introdução</h3>
                        <p>
                            Anúncios estão incorporados no nosso aplicativo, de forma a sustentar e manter os nossos servidores em funcionamento, pois ajuda-nos nos seus custos.
                        </p>

                        <h5 className="mt-3 uk-text-italic">O que são "Anúncios"?</h5>
                        <p>
                            Anúncios são publicidades mostradas ao longo de certas acções feitas no aplicativo. São publicidades de produtos e serviços oferecidos por terceiros.
                        </p>

                        <h5 className="mt-3 uk-text-italic">Porquê o app contém estes anúncios?</h5>
                        <p>
                            Exames Moçambique é um aplicativo totalmente gratuíto para todos. Mas para nós, a sustentabilidade e a manutenção dos servidores não.<br />
                            Para poder sustentar os nossos servidores e fazer com que Exames Moçambique continue sendo oferecido gratuitamente para sempre, foi imprescindível colocar anúncios publicitários dentro do aplicativo.
                        </p>
                    </div>
                    <div className="mt-5">
                        <h2>Como remover anúncios no seu aplicativo?</h2>
                        <p>Adquira já o <Link to="/info/premium">Premium</Link> e tenha todos os anúncios removidos no seu app. Tornamos-lhe fácil para adquirí-lo:</p>
                        <span className="uk-badge bg-primary fw-bold">1</span> Abra o aplicativo.<br />
                        <span className="uk-badge bg-primary fw-bold">2</span> Entre em Definições. (<img src={SettingsButtonImage} width="36px" alt="Botão de definições no app" />)<br />
                        <span className="uk-badge bg-primary fw-bold">3</span> E por fim, selecione a opção Premium e siga as instruções.<br />

                        <div className="mt-3">
                            <small>
                                <strong>Nota:</strong> São aplicados os <Link to="/info/premium/tc">Termos e Condições de Uso</Link> e as <Link to="/info/privacy">Políticas de Privacidade</Link>.
                            </small>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Ads;
