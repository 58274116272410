import React, { useCallback, useState, useEffect } from 'react';
import './Console.stylesheet.scss';
import LogoImage from '../../images/logo.png';
import { useAuth } from "../../hooks/useAuth";
import { Link, Switch, Route, useRouteMatch, NavLink, Redirect } from 'react-router-dom';
import Login from './Login';
import PrivateRoute from "../../components/PrivateRoute";
// subpages
import ManagePremium from './subpages/ManagePremium';


const Console = () => {
    /**
     * Hooks
     */
    const auth = useAuth();
    const { path } = useRouteMatch();

    /**
     * Methods
     */
    const handleSignOut = useCallback(() => {
        if (auth.user) {
            auth.signout();
        }
    }, [auth]);


    return (
        <div className="min-vh-100">
            <div className="uk-container uk-container-small">
                <div className="uk-section">
                    <div>
                        <img src={LogoImage} width="72" alt="Exames Moçambique Logo Icon" />
                    </div>

                    <h1 className="mb-0 display-1 text-black">Exames Moçambique</h1>
                    <h3 className="display-5 text-secondary">Administration console</h3>
                    {auth.user ? (
                        <div>
                            <h4 className="mb-0">Hello <span className="text-muted">{auth.user.email}</span>!</h4>
                            <div className="text-muted">Not you? <Link onClick={handleSignOut} className="text-danger">Logout</Link></div>
                        </div>
                    ) : (
                        <div>
                            <p className="text-muted fw-bold mb-0">⚠️ Restricted access for project admins only.</p>
                            <p className="text-muted fw-bold mb-0 text-primary">👀 Actions on this page are being watched.</p>
                            <p className="text-muted fw-bold text-primary">⛔️ You should consider <Link className="text-danger" to="/">closing this page</Link> if you do not belong here.</p>
                        </div>
                    )}
                </div>
            </div>

            <div className="uk-container uk-container-small">
                <div className="row">
                    <div className="col-12 col-md-auto">
                        <div className="mb-4 container">
                            <div className="mb-4">
                                <Link to="/" className="btn btn-secondary rounded-pill text-decoration-none">
                                    <i className="fas fa-arrow-circle-left"></i> Web App
                            </Link>
                            </div>

                            <hr className="e-border" />

                            {auth.user ? (
                                <div>
                                    {/* Automatically redirect to the only route. You can change this behaviour afterwards when other options are available */}
                                    <NavLink to="/console/manage" className="btn e-btn-nav rounded-pill">Register Premium</NavLink>
                                </div>
                            ) : (
                                <div>
                                    <Redirect to="/console/login" />
                                    <NavLink to="/console/login" className="btn e-btn-nav rounded-pill">Log in</NavLink>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="col">
                        <div className="min-vh-100">
                            <Switch>
                                <PrivateRoute exact path={`${path}/manage`}>
                                    <ManagePremium />
                                </PrivateRoute>

                                <Route path={`${path}/login`}>
                                    <Login />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Console;
