export const ContribuintesDatabase = {
    data: [
        {
            name: "Arlindo Abdul (Tudo Com Lizzyman)",
            link: "https://www.tudocomlizzyman.com"
        },
        {
            name: "Assucena Orlando",
            link: ""
        },
        {
            name: "Bruno Alfredo",
            link: ""
        },
        {
            name: "Delson Imuka",
            link: "https://examesdeadmissaouem.blogspot.com/"
        },
        {
            name: "Denise Samuel",
            link: ""
        },
        {
            name: "dr. Dias Joaquim",
            link: ""
        },
        {
            name: "Elísio Magaia",
            link: ""
        },
        {
            name: "Fanuel Chacha",
            link: "http://professorchacha.com/"
        },
        {
            name: "Francisco João",
            link: ""
        },
        {
            name: "Heena Bipin",
            link: ""
        },
        {
            name: "Inilce Dzeco",
            link: ""
        },
        {
            name: "Lino Viegas",
            link: ""
        },
        {
            name: "Pedro Jeremias",
            link: ""
        },
        {
            name: "Isaque Viano",
            link: ""
        },
        {
            name: "Kushal Dilip",
            link: ""
        },
        {
            name: "Lectícia Moisés",
            link: ""
        },
        {
            name: "Marvin Salia",
            link: ""
        },
        {
            name: "Milan Virendra",
            link: ""
        },
        {
            name: "Muhammad Abdullah",
            link: ""
        },
        {
            name: "Moisés Edson da Constância",
            link: ""
        },
        {
            name: "O website do Ministério de Educação e Desenvolvimento Humano de Moçambique",
            link: "http://mined.gov.mz/"
        },
        {
            name: "O website do Instituto Superior Politécnico de Gaza",
            link: "http://ispg.ac.mz/"
        },
        {
            name: "O website da Universidade Eduardo Mondlane",
            link: "http://uem.mz/"
        },
        {
            name: "Nasmá Taquidir",
            link: ""
        },
        {
            name: "Nilton A. Vaz",
            link: ""
        },
        {
            name: "Nélia Doce",
            link: ""
        },
        {
            name: "Osvaldo Domingos",
            link: ""
        },
        {
            name: "Payal Kirit",
            link: ""
        },
        {
            name: "Plácido Nhapulo",
            link: ""
        },
        {
            name: "Rushil Pankaj",
            link: ""
        },
        {
            name: "Samarth Pradip",
            link: ""
        },
        {
            name: "Shekil Nareshpal",
            link: ""
        },
        {
            name: "Sintu Bipin",
            link: ""
        },
        {
            name: "Stélio Braga",
            link: "https://steliobraga.github.io"
        },
        {
            name: "Stélio Raimundo",
            link: ""
        },
    ]
};
