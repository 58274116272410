import React, { useState, useCallback, useEffect } from 'react';
import Exame from '../../models/Exame';
import './FavoritesSection.stylesheet.scss';
import FavoritesImage from '../../images/favorites.svg';
import Paginate from 'paginate-array';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import MoreVertTwoToneIcon from '@material-ui/icons/MoreVertTwoTone';


const FavoritesSection = ({ favorites, onOpen, onUnfavorite, onClear }) => {
    /**
     * State
     */
    const [page, goToPage] = useState(1);
    const [paginatedFavorites, setPaginatedFavorites] = useState({ data: [] });
    // ...menu specific states
    const [menuAnchorEl, setMenuAnchorEl] = useState();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    /**
     * Effects
     */
    useEffect(() => {
        const paginatedFavorites = Paginate(favorites, page, 10);
        setPaginatedFavorites(paginatedFavorites);

    }, [favorites, page]);


    /**
     * Methods
     */
    const handleUnfavorite = useCallback((e, exame) => {
        e.stopPropagation(); // prevents the click event from reaching any objects other than the current object.
        onUnfavorite(exame);

    }, [onUnfavorite]);


    const handleMenuOpen = useCallback((e) => {
        setMenuAnchorEl(e.currentTarget);
        setIsMenuOpen(true);
    }, []);

    const handleMenuClose = useCallback(() => {
        setIsMenuOpen(false);
    }, []);


    /**
     * Renderers
     */
    const renderFavoritesList = useCallback(() => {
        return paginatedFavorites.data.map((favoriteExame, index) => (
            <div tabIndex="0"
                key={index} 
                onKeyPress={(e) => {
                    if (e.key === " " || e.key === "Enter") {
                        // when spacebar or enter key is hit!
                        onOpen(new Exame(favoriteExame));
                        e.preventDefault();
                    }
                }}
                onClick={() => onOpen(new Exame(favoriteExame))} 
                className="e-favorites-list-item mb-3">

                <div className="row justify-content-between align-items-center">
                    <div className="col">
                        <div>{ favoriteExame.classeOuInstituto } • { favoriteExame.disciplina }</div>
                        <p className="m-0 fw-bolder">{ favoriteExame.ano } { favoriteExame.epoca ? `– ${favoriteExame.epoca}` : '' }</p>
                    </div>

                    <div className="col-auto">
                        <IconButton aria-label="favorite" onClick={(e) => handleUnfavorite(e, favoriteExame)}>
                            <StarRoundedIcon className="text-yellow" fontSize="large" />
                        </IconButton>
                    </div>
                </div>
            </div>
        ));

    }, [handleUnfavorite, onOpen, paginatedFavorites.data]);

    
    const renderPageLinks = useCallback(() => {
        const _pageLinks = [];
        for (let p = 0; p < paginatedFavorites.totalPages; p++) {

            const pageNum = p + 1; // page number
            const isOnCurrentPage = paginatedFavorites.currentPage === pageNum;
            
            const link = (
                <div key={pageNum} className="col-auto mr-2">
                    <button onClick={() => goToPage(pageNum)} 
                        className={`btn e-page-btn ${isOnCurrentPage ? 'active' : ''}`}>
                        { pageNum }
                    </button>
                </div>
            );
            _pageLinks.push(link);
        }
        return _pageLinks;

    }, [paginatedFavorites.totalPages, paginatedFavorites.currentPage]);


    return (
        <div className="uk-animation-slide-right-small">
            <div className="mb-4">
                <img src={ FavoritesImage } width="64" />
            </div>

            <div className="row justify-content-between align-items-center">
                <div className="col">
                    <h2 className="text-secondary m-0">
                        Favoritos <span title={`${paginatedFavorites.total} exames marcados como favoritos.`} className="badge bg-secondary">{paginatedFavorites.total}</span>
                    </h2>
                </div>

                { favorites.length > 0 && (
                    <div className="col-auto">
                        <IconButton aria-label="Menu options" 
                            onClick={handleMenuOpen}>
                            <MoreVertTwoToneIcon className="text-secondary" />
                        </IconButton>

                        <Menu keepMounted
                            classes={{
                                paper: "bg-light"
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            anchorEl={menuAnchorEl}
                            onClose={handleMenuClose}
                            open={isMenuOpen}>
                            
                            <MenuItem disabled={true} className="text-muted fw-bold">
                                <small>Mais opções</small>
                            </MenuItem>

                            <MenuItem onClick={onClear} className="fw-bold text-danger">
                                Limpar favoritos
                            </MenuItem>
                        </Menu>

                    </div>
                )}
            </div>
            

            <div className="e-favorites-list mt-3">
                { favorites.length ? (
                    <>
                        { renderFavoritesList() }
                    </>

                ) : (
                    <>
                        <h6 className="text-black">Vazio</h6>
                        <p className="text-black">Encontre aqui todos os exames marcados como favoritos por sí</p>
                    </>
                ) }
            </div>


            { paginatedFavorites.totalPages > 1 && (
                <>
                    <hr className="e-border" />
                    <div className="e-pagination p-4">
                        <nav aria-label="Favorites navigation"> 
                            <div className="row gx-0 gy-3 justify-content-center">
                                { renderPageLinks() }
                            </div>

                            <div className="row mt-3 gx-0 gy-3 justify-content-center">
                                <div className="col-auto">
                                    <button disabled={page === 1} 
                                        className="btn e-page-btn"
                                        onClick={() => {
                                            if (page !== 1) {
                                                goToPage(prev => prev -1);
                                            }
                                        }}>

                                        <i className="fas fa-angle-left"></i> Anterior
                                    </button>
                                </div>

                                <div className="col-auto">
                                    <button disabled={page === paginatedFavorites.totalPages} 
                                        onClick={() => {
                                            if (page !== paginatedFavorites.totalPages) {
                                                goToPage(prev => prev + 1);
                                            }
                                        }} 
                                        className="btn e-page-btn">

                                        Próximo <i className="fas fa-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </nav>
                    </div>
                </>
            ) }

        </div>
    );
};

export default FavoritesSection;
