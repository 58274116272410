import React, { useState, useCallback, useEffect } from 'react';
import Exame from '../../models/Exame';
import './RecentsSection.stylesheet.scss';
import RecentsImage from '../../images/recents.svg';
import Paginate from 'paginate-array';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import MoreVertTwoToneIcon from '@material-ui/icons/MoreVertTwoTone';

const RecentsSection = ({ recents, onOpen, onRemove, onClear }) => {
    /**
     * State
     */
    const [page, goToPage] = useState(1);
    const [paginatedRecents, setPaginatedRecents] = useState({ data: [] });
    // ...menu specific states
    const [menuAnchorEl, setMenuAnchorEl] = useState();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    /**
     * Effects
     */
    useEffect(() => {
        const paginatedRecents = Paginate(recents, page, 10);
        setPaginatedRecents(paginatedRecents);

    }, [recents, page]);


    /**
     * Methods
     */
    const handleRemove = useCallback((e, recentExame) => {
        e.stopPropagation(); // prevents the click event from reaching any objects other than the current object.
        onRemove(recentExame);

    }, [onRemove]);


    const handleMenuOpen = useCallback((e) => {
        setMenuAnchorEl(e.currentTarget);
        setIsMenuOpen(true);
    }, []);

    const handleMenuClose = useCallback(() => {
        setIsMenuOpen(false);
    }, []);


    /**
     * Renderers
     */
    const renderRecentsList = useCallback(() => {
        return paginatedRecents.data.map((recentExame, index) => (
            <div tabIndex="0"
                key={index} 
                onKeyPress={(e) => {
                    if (e.key === " " || e.key === "Enter") {
                        // when spacebar or enter key is hit!
                        onOpen(new Exame(recentExame));
                        e.preventDefault();
                    }
                }}
                onClick={() => onOpen(new Exame(recentExame))} 
                className="e-recents-list-item mb-3">

                <div className="row justify-content-between align-items-center">
                    <div className="col">
                        <div>{ recentExame.classeOuInstituto } • { recentExame.disciplina }</div>
                        <p className="m-0 fw-bolder">{ recentExame.ano } { recentExame.epoca ? `– ${recentExame.epoca}` : '' }</p>
                    </div>

                    <div className="col-auto">
                        <IconButton aria-label="remove" 
                            onClick={(e) => handleRemove(e, recentExame)}
                            className="e-recents-list-item-remove">
                            <RemoveIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
        ));

    }, [handleRemove, onOpen, paginatedRecents.data]);

    
    const renderPageLinks = useCallback(() => {
        const _pageLinks = [];
        for (let p = 0; p < paginatedRecents.totalPages; p++) {

            const pageNum = p + 1; // page number
            const isOnCurrentPage = paginatedRecents.currentPage === pageNum;
            
            const link = (
                <div key={pageNum} className="col-auto mr-2">
                    <button onClick={() => goToPage(pageNum)} 
                        className={`btn e-page-btn ${isOnCurrentPage ? 'active' : ''}`}>
                        { pageNum }
                    </button>
                </div>
            );
            _pageLinks.push(link);
        }
        return _pageLinks;

    }, [paginatedRecents.totalPages, paginatedRecents.currentPage]);


    return (
        <div className="uk-animation-slide-right-small">
            <div className="mb-4">
                <img src={ RecentsImage } width="64" />
            </div>

            <div className="row justify-content-between align-items-center">
                <div className="col">
                    <h2 className="text-secondary m-0">
                        Recentes <span title={`${paginatedRecents.total} exames lembrados.`} className="badge bg-secondary">{paginatedRecents.total}</span>
                    </h2>
                </div>

                { recents.length > 0 && (
                    <div className="col-auto">
                        <IconButton aria-label="Menu options" 
                            onClick={handleMenuOpen}>
                            <MoreVertTwoToneIcon />
                        </IconButton>

                        <Menu keepMounted
                            classes={{
                                paper: "bg-light rounded-lg"
                            }}
                            anchorEl={menuAnchorEl}
                            onClose={handleMenuClose}
                            open={isMenuOpen}>
                            
                            <MenuItem disabled={true} className="text-muted fw-bold">
                                <small>Mais opções</small>
                            </MenuItem>

                            <MenuItem onClick={onClear} className="fw-bold text-danger">
                                Limpar recentes
                            </MenuItem>
                        </Menu>

                    </div>
                )}
            </div>
            

            <div className="e-recents-list mt-3">
                { recents.length ? (
                    <>
                        { renderRecentsList() }
                    </>

                ) : (
                    <>
                        <h6 className="text-black">Vazio</h6>
                        <p className="text-black">Encontra aqui o histórico dos últimos exames baixados neste dispositivo.</p>
                    </>
                ) }
            </div>


            { paginatedRecents.totalPages > 1 && (
                <>
                    <hr className="e-border" />
                    <div className="e-pagination p-4">
                        <nav aria-label="Recents navigation"> 
                            <div className="row gx-0 gy-3 justify-content-center">
                                { renderPageLinks() }
                            </div>

                            <div className="row mt-3 gx-0 gy-3 justify-content-center">
                                <div className="col-auto">
                                    <button disabled={paginatedRecents.totalPages === 1} className="btn e-page-btn">
                                        <i className="fas fa-angle-left"></i> Anterior
                                    </button>
                                </div>

                                <div className="col-auto">
                                    <button disabled={paginatedRecents.totalPages === page} className="btn e-page-btn">
                                        Próximo <i className="fas fa-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </nav>
                    </div>
                </>
            ) }

        </div>
    );
};

export default RecentsSection;
