import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyBsqI-AGvHpvdaDyvMQhSJJE9Pt01VgN5c",
    authDomain: "examesproject.firebaseapp.com",
    databaseURL: "https://examesproject.firebaseio.com",
    projectId: "examesproject",
    storageBucket: "examesproject.appspot.com",
    messagingSenderId: "973893672817",
    appId: "1:973893672817:web:9f7a72bc037003f21760d0"
};

firebase.initializeApp(firebaseConfig);
export default firebase;
