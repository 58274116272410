import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch, useLocation, Route, Switch } from 'react-router-dom';
import ENSEImage from '../../images/ense.svg';
import ENSUImage from '../../images/ensu.svg';
import { uniq } from 'lodash';
import Exame from '../../models/Exame';
import DownloadSectionOptions from '../../components/DownloadSectionOptions';

const DownloadSection = ({ exames, selectedEnsino, onDownload }) => {
    /**
     * States
     */
	// const history = useHistory();
	// const { params, url } = useRouteMatch();
	// const location = useLocation();

    const [classesOuInstitutos, setClassesOuInstitutos] = useState([]);
    const [disciplinas, setDisciplinas] = useState([]);
    const [anos, setAnos] = useState([]);
    const [epocas, setEpocas] = useState([]);

	const [selectedClasseOuInstituto, setSelectedClasseOuInstituto] = useState("");
	const [selectedDisciplina, setSelectedDisciplina] = useState("");
	const [selectedAno, setSelectedAno] = useState("");
	const [selectedEpoca, setSelectedEpoca] = useState("");


    /**
     * Effects
     */

	// On ensino selected, load all classes ou institutos for this ensino.
	useEffect(() => {
		const _filtered = exames.filter(exame => exame.ensino === selectedEnsino);
		const _classesOuInstitutos = uniq(_filtered.map(exame => exame.classeOuInstituto));
		setClassesOuInstitutos(_classesOuInstitutos);

		// Check if previously selected classe ou instituto exists in the newly ensino:
		// If it exists, do nothing. Maintain the selection
		if (!_classesOuInstitutos.includes(selectedClasseOuInstituto)) {
			// If it doesn't, clear selection starting from disciplinas
			setSelectedClasseOuInstituto("");
			setSelectedDisciplina("");
			setSelectedAno("");
			setSelectedEpoca("");
		}

	}, [exames, selectedClasseOuInstituto, selectedEnsino]);

	// On classe ou instituto selected, load disciplinas for it
	useEffect(() => {
		const _filtered = exames.filter(exame => (
			(exame.ensino === selectedEnsino) &&
			(exame.classeOuInstituto === selectedClasseOuInstituto)
		));
		const _disciplinas = uniq(_filtered.map(exame => exame.disciplina));
		setDisciplinas(_disciplinas);

		// Check if previously selected disciplina exists in the newly selected classe ou instituto:
		// If it exists, do nothing. Maintain the selection
		if (!_disciplinas.includes(selectedDisciplina)) {
			// If it doesn't, clear selection starting from disciplinas
			setSelectedDisciplina("");
			setSelectedAno("");
		}

	}, [exames, selectedEnsino, selectedClasseOuInstituto, selectedDisciplina]);

	// On disciplina selected, load anos available for it
	useEffect(() => {
		const _filtered = exames.filter(exame => (
			(exame.ensino === selectedEnsino) &&
			(exame.classeOuInstituto === selectedClasseOuInstituto) &&
			(exame.disciplina === selectedDisciplina)
		));
		const _anos = uniq(_filtered.map(exame => exame.ano));
		// Sort and set it
		setAnos(_anos.sort());

		// Check if previously selected ano exists in the newly selected disciplina of the classe ou instituto:
		// If it exists, do nothing. Maintain the selection
		if (!_anos.includes(selectedAno)) {
			// If it doesn't, clear selections starting from anos
			setSelectedAno("");
		}

	}, [exames, selectedEnsino, selectedClasseOuInstituto, selectedDisciplina, selectedAno]);

	// On ano selected, load epocas available for it
	useEffect(() => {
		if (selectedEnsino === "Ensino Secundario") {
			const _filtered = exames.filter(exame => (
				(exame.ensino === selectedEnsino) &&
				(exame.classeOuInstituto === selectedClasseOuInstituto) &&
				(exame.disciplina === selectedDisciplina) &&
				(exame.ano === selectedAno)
			));
			const _epocas = uniq(_filtered.map(exame => exame.epoca));
			setEpocas(_epocas);

		} else {
			// Reset epocas (or set it to unset)
			setEpocas([]);
		}


	}, [exames, selectedAno, selectedClasseOuInstituto, selectedDisciplina, selectedEnsino]);

    /**
     * Methods
     */

	/**
	 * When ano is selected
	 *
	 * - On ensino secundário, just make the selection active
	 * because we need to select Epoca after this (and that's the
	 * one responsible for showing the download prompt).
	 *
	 * - On ensino superior show the download prompt
	 *
	 * @param {String} ano the selected year
	 */
	 const handleAnoSelection = useCallback((ano) => {
		setSelectedEpoca(""); // Clear the epoca selection (if any) from Ensino Secundario
		if (selectedEnsino === "Ensino Superior") {
			// On ensino superior also show the download prompt
			// for the user to choose enunciado or guia, because ano
			// is the last selection here.
			const selectedExame = new Exame({
				ensino: selectedEnsino,
				classeOuInstituto: selectedClasseOuInstituto,
				disciplina: selectedDisciplina,
				ano: ano, // it's important to use variable directly instead of the state, because the state takes some time to set and is not available for use instantly.
				epoca: selectedEpoca
			});
			onDownload(selectedExame);

		} else {
			setSelectedAno(ano);
		}
	}, [selectedEnsino, selectedClasseOuInstituto, selectedDisciplina, selectedEpoca, onDownload]);

	/**
	 * When epoca is selected
	 * Show the download prompt (Ensino Secundario)
	 *
	 * This only happens for Ensino Secundario where each exame is divided into epocas
	 *
	 * @param {String} epoca the selected epoca
	 */
	const handleEpocaSelection = useCallback((epoca) => {
		// setSelectedEpoca(epoca);
		const selectedExame = new Exame({
			ensino: selectedEnsino,
			classeOuInstituto: selectedClasseOuInstituto,
			disciplina: selectedDisciplina,
			ano: selectedAno,
			epoca: epoca // it's important to use variable directly instead of the state, because the state takes some time to set and is not available for use instantly.
		});
		onDownload(selectedExame);

	}, [selectedEnsino, selectedClasseOuInstituto, selectedDisciplina, selectedAno, onDownload]);



    return (
        <div>
            <div>
				<div className="mb-4 animate__animated animate__fadeIn animate__faster">
					<img title={selectedEnsino} src={ selectedEnsino === "Ensino Secundario" ? ENSEImage : ENSUImage } width="64" alt={selectedEnsino}/>
				</div>

				<DownloadSectionOptions options={classesOuInstitutos}
					selectedOption={selectedClasseOuInstituto}
					title={selectedEnsino === "Ensino Secundario" ? "Escolha a classe" : "Escolhe a universidade"}
					onOptionClick={setSelectedClasseOuInstituto} />
            </div>

			<div>
				{ disciplinas.length > 0 && (
					<DownloadSectionOptions options={disciplinas}
						selectedOption={selectedDisciplina}
						title="Escolhe a disciplina"
						onOptionClick={setSelectedDisciplina} />
				) }
			</div>

			<div>
				{ anos.length > 0 && (
					<DownloadSectionOptions options={anos}
						selectedOption={selectedAno}
						title="Escolhe o ano"
						onOptionClick={handleAnoSelection} />
				) }
			</div>

            <div>
                { epocas.length > 0 && (
					<DownloadSectionOptions options={epocas}
						selectedOption={selectedEpoca}
						title="Escolhe a epoca"
						onOptionClick={handleEpocaSelection} />
                ) }
            </div>

        </div>
    );
};

export default DownloadSection;
