import React from 'react';
import PrivacyIcon from '../../images/privacy.svg';
import Nav from '../../components/Nav';
import { Link } from 'react-router-dom';


const Privacy = () => {
    return (
        <div>
            <Nav />

            <section className="uk-section e-black-gradient">
                <div className="uk-container uk-container-small uk-light uk-padding-small">
                    <img width="72" src={PrivacyIcon} alt="Premium (Termos e Condições)"/>
                    <h1 className="uk-margin-remove-bottom uk-text-bold uk-margin-small-top">Políticas de Privacidade</h1>
                </div>
            </section>

            <section className="uk-section">
                <div className="uk-container uk-container-small">				
                    <h2>Introdução</h2>
                    <p>
                        <strong>Obrigado por escolher o Exames Moçambique!</strong><br/>
                        Quando utiliza o Exames Moçambique, você confia-nos com as suas informações. Estamos empenhados em manter essa confiança. Para isso, vamos começar por o ajudar a compreender as nossas práticas de privacidade.
                        <br/>
                        Esta política descreve as informações que recolhemos, como as utilizamos e as suas escolhas relativamente a estas informações.			
                    </p>

                    <h4>
                        <strong>As informações que recolhemos</strong>
                    </h4>
                    <p>
                        As seguintes informações são recolhidas pela ou em nome de Exames Moçambique:<br/>
                        1. <strong>Informações que fornece</strong><br/>
                        Poderão incluir: <br/>
                    </p>
                    <ul className="uk-list uk-list-bullet">
                        <li><strong>Perfil do Utilizador:</strong> recolhemos informações quando faz o pagamento para a remoção de anúncios via <a href="https://www.vm.co.mz/M-Pesa2">Vodacom (Pty) Ltd. M-PESA</a>. Estas incluem: o seu nome e o seu número de telefone.</li>
                        <li><strong>Informações de dispositivo:</strong> Poderemos recolher informações sobre os dispositivos que utiliza para aceder aos nossos serviços, incluindo os modelos de hardware, o endereço IP do dispositivo, os sistemas operativos e as respectivas versões, o software, os identificadores de publicidade, o tempo de uso do nosso serviço, por exemplo, mas não se limitando, na ocorrência de um erro que faça com que o aplicativo pare de funcionar no seu dispositivo.</li>
                    </ul>

                        2. <strong>Informação de utilização</strong><br/>
                        Poderão incluir: <br/>
                        <ul className="uk-list uk-list-bullet">
                            <li><strong>Cookies:</strong> são ficheiro pequenos contendo pequenas informações que são geralmente usadas como identificadores anónimos.
                                Estes são enviados para o seu navegador através de websites que visitas e são guardadas no armazenamento interno do seu dispositivo.<br/>
                                Este serviço não faz o uso de `cookies` explicitamente. Contudo, este aplicativo podera usar código de terceiros e bibliotecas 
                                que utilizem `cookies` para a coleta de informação e a melhora do seu serviço.
                            </li>
                        </ul>
                        
                        3. <strong>Informação de utilização por serviços terceirizados</strong><br/>
                        Encontre na nossa página <Link to="/info/about">Sobre Nós</Link>, quais são os serviços oferecidos por terceiros utilizados no aplicativo, e links por cada um onde poderá apreciar os termos de condições dos mesmos.<br/>

                    <h5>
                        <strong>Como usamos as informações que recolhemos</strong>
                    </h5>
                    <p>
                        O Exames Moçambique utiliza as informações que recolhe para os seguintes fins:<br/>
                        1. <strong>Fornecer serviços e funcionalidades</strong><br/>
                        O Exames Moçambique utiliza as informações que recolhe para fornecer, personalizar, manter e melhorar os nossos produtos e serviços. Isto inclui utilizar as informações para:
                        </p><ul className="uk-list uk-list-bullet">
                                <li>Verificar a sua identidade no pagamento da remoção de anúncios.</li>
                                <li>Realizar operações internas necessárias para fornecer os nossos serviços, incluindo para resolver problemas operacionais e de software, para realizar análises de dados, e para monitorizar e analisar as tendências de utilização e de actividade.</li>
                        </ul>
                        2. <strong>Procedimentos e requisitos legais</strong><br/>
                        Poderemos utilizar as informações que recolhemos para analisar ou responder a reclamações ou litígios relacionados com a sua utilização dos serviços de Exames Moçambique, ou como de outra forma permitido pela lei aplicável, ou como solicitado pelas entidades reguladoras, entidades governamentais e inquéritos oficiais.
                    <p></p>


                    <h4 href="permissions">
                        <strong>Informações que fornece por permissão</strong>
                    </h4>
                    <p>
                        O nosso aplicativo Exames Moçambique, poderá em algumas acções do usuário, pedir a permissão do uso de:
                        </p><ul className="uk-list uk-list-bullet">
                            <li><strong>Armazenamento Interno do seu telemóvel</strong>: para poder salvar os exames no seu telemóvel e poder visualizar em offline os mesmos ficheiros. O aplicativo somente faz as operações de leitura e escrita no armazenamento interno, em um directório próprio, denominado "Exames-App", que é criada automaticamente após a permissão do uso for aceite pelo usuário do aplicativo.</li>
                        </ul>
                        Não temos acesso, não guardamos, não fazemos nenhuma operação em nenhum ficheiro do seu telemóvel. Em momento algum os seus ficheiros são recolhidos por nós. O aplicativo só faz a leitura dos seus arquivos, localmente, no seu dispositivo.
                        <br/>
                        ~ Resumindo: <i>"<u>O que acontece no seu dispositivo</u>, <u>permanece no seu dispositivo</u>"</i>.
                    <p></p>

                    <h4>
                        <strong>Segurança e Escolha</strong>
                    </h4>
                    <p>
                        O Exames Moçambique valoriza sua confiança em nos fornecer suas informações pessoais. Não partilhamos nenhum dos seus dados recolhidos por nós, com nenhum terceiro.<br/>
                        <br/>
                        Se em algum momento pretender copiar, modificar ou remover as suas informações, poderá o fazer contactando-nos pelo email <a href="mailto:kishan_jadav@hotmail.com">kishan_jadav@hotmail.com</a>.
                    </p>

                    <h4>
                        <strong>Links para outros websites ou serviços</strong>
                    </h4>
                    <p>
                        Em nosso aplicativo ou website, poderão se conter links para outros websites ou serviços. Ao clicar-se em um link de terceiro, serás redirecionado a esse site. Notando que esses sites externos não são operados pelo Exames Moçambique. Assim sendo, encorrajamo-lo que reveja as Políticas de Privacidades desses websites ou serviços. Exames Moçambique não tem controle sobre e assume nenhuma responsabilidade no conteúdo, política de privacidade ou práticas de nenhum website ou serviço terceirizado.
                    </p>

                    <h4>
                        <strong>Atualização a esta política</strong>
                    </h4>
                    <p>
                        Poderemos ocasionalmente atualizar esta política. Se procedermos a alterações significativas, notificá-lo-emos das alterações através do nosso website. Dentro dos limites permitidos pela lei aplicável, ao utilizar os nossos serviços após essa notificação, está a consentir as atualizações feitas a esta política.
                        Encorajamo-lo a rever periodicamente esta política em relação à informação mais recente sobre as nossas práticas de privacidade.
                    </p>


                </div>
            </section>
        </div>
    );
};

export default Privacy;
