import React from 'react';
import PremiumIcon from '../../images/premium.svg';
import Nav from '../../components/Nav';
import { Link } from 'react-router-dom';


const PremiumTC = () => {
    return (
        <div>
            <Nav />

            <section className="uk-section e-darkblue-gradient">
                <div className="uk-container uk-container-small uk-light uk-padding-small">
                    <img width="72" src={PremiumIcon} alt="Premium (Termos e Condições)" />
                    <h1 className="uk-margin-remove-bottom uk-text-bold uk-margin-small-top">Premium (TC)</h1>
                    <h4 className="mb-3">Termos e condições</h4>
                    <p>
                        Ao assinar o <Link to="/info/premium">Premium</Link> no nosso aplicativo, automaticamente aceitas os nossos termos de condições para o uso devido deste serviço.
                    </p>
                </div>
            </section>

            <section className="uk-section">
                <div className="uk-container uk-container-small uk-padding-small">
                    <h5>Os termos e condições para o uso do "Premium" no nosso aplicativo, são:</h5>
                    <table className="uk-table uk-table-justify uk-table-divider">
                        <tbody>
                            <tr><td><strong>–</strong> Uma chave de activação não deverá ser usada em mais de um dispositivo.</td></tr>
                            <tr><td><strong>–</strong> Em casos onde se pretende remover anúncios de um outro dispositivo, com o mesmo número, deverá efectuar novamente o pagamento, de forma a receber uma nova chave de activação.</td></tr>
                            <tr><td><strong>–</strong> Cada chave de activação será automaticamente associada ao dispositivo com o qual a utilizou pela primeira vez.</td></tr>
                            <tr><td><strong>–</strong> Não deverá revelar a(s) sua(s) chave(s) de activação a mais ninguém.</td></tr>
                            <tr><td><strong>–</strong> Exames Moçambique reserva o direito de cancelar uma chave de activação, com um aviso prévio, nos casos em que forem detectados um abuso a qualquer um dos pontos supracitados.</td></tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    );
};

export default PremiumTC;
