import React from 'react';
import Logo from '../images/logo.png';
import { Link } from 'react-router-dom';

const NoMatch = () => {
    return (
        <div className="container min-vh-100 p-5">
            <div className="row align-items-center">
                <div className="col-auto">
                    <img src={Logo} alt="Exames Moçambique Logo" width="96px" />
                </div>
                <div className="col">
                    <h1 className="text-primary">Exames Moçambique</h1>
                </div>
            </div>

            <div className="mt-5">
                <h1 className="display-1">404 🙊</h1>
                <h3 className="display-5 text-muted">Página não encontrada</h3>
            </div>

            <div className="mt-5">
                <Link to="/" className="btn btn-dark btn-lg">VOLTAR A HOME</Link>
            </div>
        </div>
    );
};

export default NoMatch;
