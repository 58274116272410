import React, { useState, useCallback } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import random from 'random-string-generator';
import { size } from 'lodash';
import { isMobileNumberValid, parseMobileNumber } from "mozambique-utils";
import firebase from '../../../Firebase';
import Spinner from "../../../components/Spinner";
import toast from 'react-hot-toast';
import axios from "axios";

const ManagePremium = () => {

    /**
     * States
     */
    const [phoneNumber, setPhoneNumber] = useState("");
    const [activationKey, setActivationKey] = useState("");
    const [registered, setRegistered] = useState(false);
    const [messageSent, setMessageSent] = useState(false);
    const [loading, setLoading] = useState(false);


    /**
     * Methods
     */
    // Generate a random 6 char string for key.
    const genRandomActivationKey = useCallback(() => {
        const _randomActivationKey = random(6).toLowerCase();
        setActivationKey(_randomActivationKey);
    }, []);


    // On form submission
    const onSubmit = useCallback((e) => {
        e.preventDefault(); // prevent default form submission. Handle it with javascript instead

        setLoading(true);

        // Validate phone number
        const isPhoneNumberValid = isMobileNumberValid(phoneNumber, {
            allowedOperator: ["vodacom"]
        });
        const isKeyValid = size(activationKey) === 6;

        if (isPhoneNumberValid && isKeyValid) {
            // parse it and get the localFormat to send to the backend.
            const parsed = parseMobileNumber(phoneNumber);
            const locallyFormatedPhoneNumber = parsed.data.localFormat;
            setPhoneNumber(locallyFormatedPhoneNumber);

            const database = firebase.database();
            database.ref('paid_users/' + locallyFormatedPhoneNumber).once('value').then(function (snapshot) {
                if (snapshot.val() === null) {
                    // If paying for the first time:
                    database.ref('paid_users/' + locallyFormatedPhoneNumber).set({
                        "key": activationKey, // this line is here bc of compatibility with older version of the app.
                        "paid_times": 1,
                        "payments": {
                            1: {
                                "key": activationKey
                            }
                        }
                    }, function (error) {
                        // handle opperation callbacks
                        setRegistered(error ? false : true);
                        setLoading(false);
                    });

                } else {
                    // Paying again.
                    // Not the first time that this user has paid
                    database.ref('paid_users/' + locallyFormatedPhoneNumber).once('value').then(function (snapshot) {
                        // Get the number of payments made until now
                        let paid_times = snapshot.val().paid_times;
                        let new_paid_times = paid_times + 1;

                        var user_object = {
                            "paid_times": new_paid_times
                        };
                        user_object['payments'] = snapshot.val().payments;
                        user_object['payments'][new_paid_times] = {
                            "key": activationKey
                        };

                        database.ref('paid_users/' + locallyFormatedPhoneNumber).update(user_object, function (error) {
                            // handle opperation callbacks
                            setRegistered(error ? false : true);
                            setLoading(false);
                        });
                    });
                }
            });

        } else {
            toast.error("Phone number or key is invalid.");
            setRegistered(false);
            setLoading(false);
        }
    }, [phoneNumber, activationKey]);


    const onSendSMS = useCallback((e) => {
        e.preventDefault(); // prevent default form submission. Handle it with javascript instead
        setLoading(true);

        const url = "https://mozexames.com/api/private/sms";
        const data = {
            to: phoneNumber,
            message: "Ola caro estudante, muito obrigado pela sua contribuição. A sua chave de activação é `" + activationKey + "`.\n\nEquipe Exames[mz]\nwww.mozexames.com"
        };

        firebase.auth().currentUser.getIdToken().then((token) => {
            axios.post(url, data, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then((res) => {
                if (res.status === 200) {
                    toast.success(`Success! Message sent. Status Code: ${res.status}`);
                    setMessageSent(true); // reset

                } else {
                    toast.error(`Failed! Status: ${res.statusText} - Code: ${res.status}`);
                    setMessageSent(false);
                }
                setLoading(false);
            }).catch((error) => {
                toast.error(`Error: ${error}`);
                setMessageSent(false);
                setLoading(false);
            });
        });
    }, [activationKey, phoneNumber]);


    return (
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-auto">
                    <h4 className="text-black mb-4">Register a new premium user</h4>
                </div>
                <div className="col-auto">
                    <div>
                        <Spinner enabled={loading} />
                    </div>
                </div>
            </div>

            <form onSubmit={onSubmit}>
                <input disabled={loading || registered} value={phoneNumber} onChange={(e) => setPhoneNumber(e.currentTarget.value)} max="14" maxLength="14" type="text" className="e-input w-100 mb-3" placeholder="84|85" />
                <div className="row align-items-center">
                    <div className="col">
                        <input disabled={loading || registered} value={activationKey} onChange={(e) => setActivationKey(e.currentTarget.value.toLowerCase())} type="text" className={`e-input w-100 ${size(activationKey) === 6 ? 'valid' : ''}`} max="6" maxLength="6" placeholder="6 characters key" />
                    </div>
                    <div className="col-auto">
                        <IconButton disabled={loading || registered} onClick={genRandomActivationKey}>
                            <AutorenewIcon className="text-muted" fontSize="large" />
                        </IconButton>
                    </div>
                </div>

                <hr className="e-border my-4" />

                <div className="row align-items-center gy-4">
                    <div className="col-12 col-md-auto">
                        <button type="submit" disabled={registered || loading} className="btn btn-lg e-btn-register fw-bold">
                            {registered ? (
                                <><i class="fas fa-check"></i> Registered!</>
                            ) : (
                                <>Register</>
                            )}
                        </button>
                    </div>
                    <div className="col-12 col-md-auto">
                        <button type="button" onClick={onSendSMS} disabled={!registered || messageSent || loading} className="btn btn-lg e-btn-send fw-bold">
                            {messageSent ? (
                                <><i class="fas fa-check-circle"></i> Message sent!</>
                            ) : (
                                <><i class="fas fa-paper-plane"></i> Send Message</>
                            )}
                        </button>
                    </div>
                </div>
            </form>

        </div >
    );
};

export default ManagePremium;
