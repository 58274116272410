import React from 'react';
import './Info.stylesheet.scss';
import { Link } from "react-router-dom";
import UsIcon from '../../images/us.svg';
import PremiumIcon from '../../images/premium.svg';
import PrivacyIcon from '../../images/privacy.svg';
import Logo from '../../images/logo.png';


const Info = () => {
    return (
        <div className="min-vh-100 bg-light">
            <div className="uk-container uk-container-small">
                <div className="uk-section">
                    <div className="row justify-content-between">
                        <div className="col">
                            <img src={UsIcon} width="196px" alt="Information logo icon" />
                        </div>
                        <div className="col-auto">
                            <Link to="/" className="btn e-btn-back rounded-pill text-decoration-none">
                                <i className="fas fa-arrow-circle-left"></i> Voltar ao Web App
                            </Link>
                        </div>
                    </div>

                    <h1 className="mb-0 display-1 text-primary">Exames Moçambique</h1>
                    <h3 className="display-5">Mais Informações</h3>

                    <p className="text-black">
                        Todas as informações acerca de Exames Moçambique em um só lugar.
                        Veja informações de como são utilizados os dados coletados pelos nossos serviços (Política de Privacidade), quais são os serviços terceirizados usados no nosso aplicativo e apoios que o Exames Moçambique tem o previlégio de ter.
                    </p>

                    <div className="row gy-3 mt-5" data-masonry='{"percentPosition": false }'>
                        <div className="col-md-6">
                            <div className="e-card p-5 text-black uk-box-shadow-large">
                                <img className="mb-4" width="64px" src={Logo} alt="Premium Icon" />
                                <h4 className="card-title text-white mt-5 mb-3">Sobre Nós</h4>
                                <div className="mb-2">
                                    <Link className="text-light" to="/info/about">
                                        Mais informações e os nossos apoios
                                    </Link>
                                </div>

                                <h4 className="card-title text-white mt-4">Contactos</h4>
                                <div className="mb-2 text-light">
                                    Email: <a href="mailto:kishan@mozexames.com?subject=Exames Moçambique - Web">kishan_jadav@hotmail.com</a>
                                </div>
                                <div className="mb-2 text-light">
                                    Facebook: <a href="https://facebook.com/exames.moz">@exames.moz</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="e-card e-black-gradient p-5 text-white uk-box-shadow-large">
                                <div className="row mb-5">
                                    <div className="col-auto">
                                        <img className="mb-4" width="64px" src={PremiumIcon} alt="Premium Icon" />
                                    </div>
                                    <div className="col-auto">
                                        <img className="mb-4" width="64px" src={PrivacyIcon} alt="Premium - Privacy Icon" />
                                    </div>
                                </div>
                                <h4 className="card-title text-white mb-3">Serviços</h4>
                                <div className="mb-2">
                                    <Link className="text-light" to="/legal/premium">
                                        Premium
                                    </Link>
                                </div>
                                <div className="mb-2">
                                    <Link className="text-light" to="/info/ads">
                                        Anúncios
                                    </Link>
                                </div>
                                <div className="mb-2">
                                    <Link className="text-light" to="/info/premium/tc">
                                        Termos e Condições do Premium
                                    </Link>
                                </div>
                                <div>
                                    <Link className="text-light" to="/legal/privacy">
                                        Políticas de Privacidade
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Info;
