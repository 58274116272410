import React from 'react';
import './App.stylesheet.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

// Import pages
import NoMatch from './pages/NoMatch';
import Home from './pages/home/Home';
import Info from './pages/info/Info';
import Premium from './pages/info/Premium';
import PremiumTC from './pages/info/PremiumTC';
import AboutUs from './pages/info/AboutUs';
import Ads from './pages/info/Ads';
import Privacy from './pages/info/Privacy';
// Private pages
import Console from './pages/console/Console';
import { ProvideAuth } from './hooks/useAuth';



// Main app component
function App() {
	return (
		<ProvideAuth>
			<Router>
				<Switch>
					<Route exact path="/" render={() => <Home />} />
					<Route exact path="/:section(favoritos|recentes)" render={() => <Home />} />
					<Route exact path="/e/:ensino?" render={() => <Home />} />

					<Route exact path="/info" component={Info} />
					<Route exact path={["/info/premium/tc", "/legal/premium/tc"]} component={PremiumTC} />
					<Route exact path="/info/about" component={AboutUs} />
					<Route exact path="/info/ads" component={Ads} />
					<Route exact path={["/info/premium", "/legal/premium"]} component={Premium} />
					<Route exact path={["/info/privacy", "/legal/privacy"]} component={Privacy} />

					<Route path="/console" component={Console} />
					<Route path="*" component={NoMatch} />
				</Switch>
			</Router>

			<Toaster position="top-right" toastOptions={{
				style: {
					backgroundColor: '#43250E',
					color: '#fff',
					zIndex: 1,
				},
			}} />
		</ProvideAuth>
	);
}

export default App;
