import React from 'react';
import './DownloadSectionOptions.stylesheet.scss';

const DownloadSectionOptions = ({ options, selectedOption, title, onOptionClick }) => {

    return (
        <div>
            <h4 className="mt-4 text-muted">{title}</h4>
            <div className="row e-options animate__animated animate__fadeIn animate__faster">
                { options.map((option) => (
                    <div key={option}
                        tabIndex="0"
                        className={`col-auto m-2 rounded-pill e-options-item ${selectedOption === option ? 'active' : '' }`}
                        onKeyPress={(e) => {
                            if (e.key === " " || e.key === "Enter") {
                                // if the spacebar or the enter key was pressed!
                                onOptionClick(option);
                                e.preventDefault(); // prevents the browser to scroll the page when spacebar is hit.
                            }
                        }}
                        onClick={() => onOptionClick(option)}>
                        { option }
                    </div>
                )) }
            </div>
        </div>
    );
};

export default DownloadSectionOptions;
