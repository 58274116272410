import PrivacyIcon from '../images/privacy.svg';
import { Route } from "react-router";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const PrivateRoute = ({ children, ...rest }) => {
    const auth = useAuth();

    return (
        <Route {...rest} render={({ location }) => {
            return auth.user ? (
                children

            ) : (
                <div className="text-center">
                    <img src={PrivacyIcon} width="64" />
                    <p className="mt-3">
                        You must <Link to="/console/login">authenticate</Link>.
                    </p>
                </div>
            );
        }} />
    );

};

export default PrivateRoute;