export const ExamesDatabase = {
  metadata: {
    generatedTime: {
      stampUnixMs: 1670019538667,
      zone: "Europe/London",
    },
    author: "kishan_jadav@hotmail.com | https://mozexames.com/",
    version: 1670019538668,
  },
  exames: [
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2005",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2006",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2006",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2007",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2008",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2009",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2009",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2010",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2017",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2019",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2020",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2009",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2015",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2016",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2020",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2015",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2017",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2011",
      epoca: "1a Epoca ",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2015",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2015",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2017",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2019",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2020",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2022",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "1995",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "1997",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2004",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2005",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2006",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2007",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2008",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2008",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2009",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2009",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2010",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2010",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2018",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2019",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2019",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2016",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2019",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2020",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2020",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2022",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2016",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2019",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2005",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2009",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2016",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2019",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2020",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2009",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2010",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2015",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2015",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2016",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2019",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2005",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Biologia",
      ano: "2020",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2009",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Biologia",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Desenho",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Filosofia",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2005",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2009",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Fisica",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Fisica",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Frances",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2005",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Geografia",
      ano: "2020",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Geografia",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2005",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2009",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2010",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2015",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2017",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2005",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2006",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2007",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2008",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2009",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2009",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2017",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2015",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2017",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2005",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2020",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2009",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2009",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2010",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2010",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2017",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2005",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2009",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2015",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2016",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2020",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2005",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2006",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2007",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2007",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2008",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2009",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2010",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2015",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2017",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Historia",
      ano: "2019",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2008",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Historia",
      ano: "2019",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2005",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2006",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2007",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2008",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2009",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2009",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2015",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2017",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2019",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Ingles",
      ano: "2020",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2015",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Ingles",
      ano: "2019",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2005",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2009",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2015",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2015",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2016",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2017",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2018",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2019",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Matematica",
      ano: "2020",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2008",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2008",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2009",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2009",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2010",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2010",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2015",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2015",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2016",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2017",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2018",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2019",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2019",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2020",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Matematica",
      ano: "2022",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2009",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2015",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2015",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2016",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2017",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2019",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Portugues",
      ano: "2020",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2008",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2015",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2016",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2019",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2020",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Portugues",
      ano: "2022",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2009",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2016",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2019",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "10a Classe",
      disciplina: "Quimica",
      ano: "2020",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2000",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2000",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2001",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2001",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2002",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2002",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2003",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2003",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2011",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2011",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2012",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2012",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2013",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2013",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2014",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2014",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2017",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2019",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2020",
      epoca: "1a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Secundario",
      classeOuInstituto: "12a Classe",
      disciplina: "Quimica",
      ano: "2020",
      epoca: "2a Epoca",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Biologia",
      ano: "2015",
      epoca: "",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Matematica",
      ano: "2015",
      epoca: "",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPT",
      disciplina: "Portugues",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Biologia",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Filosofia",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Fisica",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Fisica",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Frances",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Geografia",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Historia",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Ingles",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Matematica",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Matematica",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Musica",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Portugues 1",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Portugues 2",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Quimica",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Guia",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "IFP",
      disciplina: "Matematica",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "IFP",
      disciplina: "Matematica",
      ano: "2015",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "IFP",
      disciplina: "Matematica",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "IFP",
      disciplina: "Matematica",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "IFP",
      disciplina: "Matematica",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "IFP",
      disciplina: "Matematica",
      ano: "2020",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "IFP",
      disciplina: "Portugues",
      ano: "2012",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "IFP",
      disciplina: "Portugues",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "IFP",
      disciplina: "Portugues",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "IFP",
      disciplina: "Portugues",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "IFP",
      disciplina: "Portugues",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Biologia",
      ano: "2015",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Biologia",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Biologia",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Biologia",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Biologia",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Cirurgia",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Cirurgia",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Cultura Geral",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Fisica",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Historia",
      ano: "2015",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Historia",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Matematica",
      ano: "2015",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Matematica",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Matematica",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Matematica",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Matematica",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Portugues",
      ano: "2015",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Portugues",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Portugues",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Portugues",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Portugues",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Quimica",
      ano: "2015",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Quimica",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Quimica",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISCISA",
      disciplina: "Quimica",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPG",
      disciplina: "Fisica",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPG",
      disciplina: "Fisica",
      ano: "2020",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPG",
      disciplina: "Matematica",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPG",
      disciplina: "Matematica",
      ano: "2020",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPG",
      disciplina: "Portugues",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPG",
      disciplina: "Portugues",
      ano: "2020",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPG",
      disciplina: "Quimica",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPG",
      disciplina: "Quimica",
      ano: "2020",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPT",
      disciplina: "Matematica",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPT",
      disciplina: "Matematica",
      ano: "2011",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPT",
      disciplina: "Matematica",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPT",
      disciplina: "Matematica",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPT",
      disciplina: "Matematica",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPT",
      disciplina: "Matematica",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPT",
      disciplina: "Portugues",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPT",
      disciplina: "Portugues",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPT",
      disciplina: "Portugues",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISPT",
      disciplina: "Portugues",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Anestesia",
      ano: "2020",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Biologia",
      ano: "2020",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Fisica",
      ano: "2020",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Geografia",
      ano: "2007",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Geografia",
      ano: "2008",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Geografia",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Geografia",
      ano: "2011",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Geografia",
      ano: "2012",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Geografia",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Geografia",
      ano: "2015",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Geografia",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Geografia",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Historia",
      ano: "2007",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Historia",
      ano: "2008",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Historia",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Historia",
      ano: "2011",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Historia",
      ano: "2012",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Historia",
      ano: "2015",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Historia",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Historia",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Instrumentacao",
      ano: "2020",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Matematica",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Matematica",
      ano: "2020",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Portugues",
      ano: "2007",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Portugues",
      ano: "2008",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Portugues",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Portugues",
      ano: "2011",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Portugues",
      ano: "2012",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Portugues",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Portugues",
      ano: "2015",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Portugues",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Portugues",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Portugues",
      ano: "2020",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "ISRI",
      disciplina: "Quimica",
      ano: "2020",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Biologia",
      ano: "2004",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Biologia",
      ano: "2005",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Biologia",
      ano: "2007",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Biologia",
      ano: "2008",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Biologia",
      ano: "2009",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Biologia",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Biologia",
      ano: "2011",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Biologia",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Biologia",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Biologia",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Biologia",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Desenho 1",
      ano: "2005",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Desenho 1",
      ano: "2007",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Desenho 1",
      ano: "2009",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Desenho 1",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Desenho 1",
      ano: "2011",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Desenho 1",
      ano: "2012",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Desenho 1",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Desenho 1",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Desenho 1",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Desenho 2",
      ano: "2009",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Desenho 2",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Desenho 2",
      ano: "2011",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Desenho 2",
      ano: "2012",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Desenho 2",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Desenho 2",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Filosofia",
      ano: "2011",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Filosofia",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Filosofia",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Filosofia",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Filosofia",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Fisica",
      ano: "2004",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Fisica",
      ano: "2005",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Fisica",
      ano: "2006",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Fisica",
      ano: "2007",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Fisica",
      ano: "2008",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Fisica",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Fisica",
      ano: "2011",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Fisica",
      ano: "2012",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Fisica",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Fisica",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Fisica",
      ano: "2015",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Fisica",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Fisica",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Fisica",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Fisica",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Fisica",
      ano: "2020",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Frances",
      ano: "2004",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Frances",
      ano: "2005",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Frances",
      ano: "2007",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Frances",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Frances",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Frances",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Frances",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Geografia",
      ano: "2004",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Geografia",
      ano: "2005",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Geografia",
      ano: "2006",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Geografia",
      ano: "2007",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Geografia",
      ano: "2008",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Geografia",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Geografia",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Geografia",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Geografia",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Historia",
      ano: "2005",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Historia",
      ano: "2006",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Historia",
      ano: "2007",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Historia",
      ano: "2008",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Historia",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Historia",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Historia",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Historia",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Ingles",
      ano: "2004",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Ingles",
      ano: "2005",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Ingles",
      ano: "2006",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Ingles",
      ano: "2007",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Ingles",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Ingles",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Ingles",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Ingles",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Matematica",
      ano: "2004",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Matematica",
      ano: "2005",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Matematica",
      ano: "2007",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Matematica",
      ano: "2008",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Matematica",
      ano: "2009",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Matematica",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Matematica",
      ano: "2011",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Matematica",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Matematica",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Matematica",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Matematica",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Matematica",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Matematica",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Matematica",
      ano: "2020",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Musica",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Musica",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Portugues 1",
      ano: "2005",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Portugues 1",
      ano: "2007",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Portugues 1",
      ano: "2009",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Portugues 1",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Portugues 1",
      ano: "2011",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Portugues 1",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Portugues 1",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Portugues 1",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Portugues 1",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Portugues 2",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Portugues 2",
      ano: "2011",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Portugues 2",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Portugues 2",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Portugues 2",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Portugues 2",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Quimica",
      ano: "2004",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Quimica",
      ano: "2005",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Quimica",
      ano: "2006",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Quimica",
      ano: "2007",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Quimica",
      ano: "2008",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Quimica",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Quimica",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Quimica",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Quimica",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Teatro",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UEM",
      disciplina: "Teatro",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Biologia para Educacao Fisica",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Biologia para Educacao Fisica",
      ano: "2011",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Biologia",
      ano: "2007",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Biologia",
      ano: "2008",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Biologia",
      ano: "2009",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Biologia",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Biologia",
      ano: "2011",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Biologia",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Biologia",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Biologia",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Biologia",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Biologia",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Desenho",
      ano: "2011",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Desenho",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Desenho",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Desenho",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Filosofia",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Filosofia",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Filosofia",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Filosofia",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Filosofia",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Filosofia",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Filosofia",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Fisica",
      ano: "2004",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Fisica",
      ano: "2008",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Fisica",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Fisica",
      ano: "2011",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Fisica",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Fisica",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Fisica",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Fisica",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Fisica",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Fisica",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Frances",
      ano: "2008",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Frances",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Frances",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Geografia",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Geografia",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Geografia",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Geografia",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Historia",
      ano: "2009",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Historia",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Historia",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Historia",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Historia",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Historia",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Ingles",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Ingles",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Ingles",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Ingles",
      ano: "2015",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Ingles",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Ingles",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Ingles",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Matematica",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Matematica",
      ano: "2011",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Matematica",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Matematica",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Matematica",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Matematica",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Matematica",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Matematica",
      ano: "2020",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Portugues",
      ano: "2009",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Portugues",
      ano: "2010",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Portugues",
      ano: "2011",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Portugues",
      ano: "2012",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Portugues",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Portugues",
      ano: "2014",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Portugues",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Portugues",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Portugues",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Portugues",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Quimica",
      ano: "2007",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Quimica",
      ano: "2008",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Quimica",
      ano: "2009",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Quimica",
      ano: "2013",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Quimica",
      ano: "2016",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Quimica",
      ano: "2017",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Quimica",
      ano: "2018",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
    {
      ensino: "Ensino Superior",
      classeOuInstituto: "UP",
      disciplina: "Quimica",
      ano: "2019",
      epoca: "",
      enunciadoOuGuia: "Enunciado",
    },
  ],
};
