import React, { useState, useEffect, useCallback, useRef } from 'react';
import './Home.stylesheet.scss';
import 'animate.css';
import ENSEImage from '../../images/ense.svg';
import ENSUImage from '../../images/ensu.svg';
import LogoImage from '../../images/logo.png';
import PlayImage from '../../images/play-icon.svg';
import { isEqual, random, uniq, uniqWith } from 'lodash';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import toast from 'react-hot-toast';
import SuperDialog from '../../components/SuperDialog';
import { CitationsDatabase } from '../../databases/CitationsDatabase';
import { ExamesDatabase } from '../../databases/ExamesDatabase';
import Exame from '../../models/Exame';
import Logger from '../../utils/Logger';
// Page Subsection
import FavoritesSection from './FavoritesSection';
import RecentsSection from './RecentsSection';
import DownloadSection from './DownloadSection';


const Home = () => {
	const [db] = useState(ExamesDatabase);
	const [citation, setCitation] = useState({});
	const [recents, setRecents] = useLocalStorage("recents", []);
	const [favorites, setFavorites] = useLocalStorage("favorites", []);

	const [ensinos, setEnsinos] = useState([]);
    const [selectedEnsino, setSelectedEnsino] = useState("");

	// Super dialog related states
	const [selectedExame, setSelectedExame] = useState(new Exame({}));
	const [isSelectedExameFavorite, setIsSelectedExameFavorite] = useState(false);
	const [isDownloadPromptOpen, setIsDownloadPromptOpen] = useState(false);

	const location = useLocation();
	const history = useHistory();
	const sectionsContainerRef = useRef(null); // used to scroll into that container

	const [selectedSection, setSelectedSection] = useState("main");



	/** 
	 * Methods
	 */
	const manuallyShowSection = useCallback((show, sectionName) => {
		// If manually enabling/disabling it use that
		// otherwise toggle from last state.
		setSelectedEnsino("");
		setSelectedSection(show ? sectionName : "main");

		if (show) {
			// Showing the section
			// and auto-scroll into it
			sectionsContainerRef.current.scrollIntoView();
			// To location: /:section
			history.push(`/${sectionName}`);
		
		} else {
			// Hiding section
			// Show main
			history.push("/");
		}
	}, [history]);



	const toggleRecentsBtn = useCallback(() => {
		// If manually enabling/disabling it use that
		// otherwise toggle from last state.
		setSelectedEnsino("");
		setSelectedSection(prevSection => {
			return prevSection !== "recentes" ? "recentes" : "main";
		});

		if (selectedSection !== "recentes") {
			// Opening recents now!
			sectionsContainerRef.current.scrollIntoView();
			history.push("/recentes");
		
		} else {
			history.push("/");
		}

	}, [history, selectedSection]);

	const toggleFavoritesBtn = useCallback(() => {
		setSelectedEnsino("");
		setSelectedSection(prevSection => {
			return prevSection !== "favoritos" ? "favoritos" : "main";
		});

		if (selectedSection !== "favoritos") {
			// Opening recents now!
			sectionsContainerRef.current.scrollIntoView();
			history.push("/favoritos");
		
		} else {
			history.push("/");
		}
			
	}, [history, selectedSection]);


	const onFavorite = useCallback((exame, isEnabling) => {
		// Look up this exame in favorite list
		if (isEnabling) {
			// Mark as favorite
			const newFaves = [exame.toObject(), ...favorites];
			// Remember up to 50 last downloaded exams
			if (newFaves.length > 50) {
				// When we reach 50, for every new remembered exame,
				// remove the oldest one. (so we maintain in the 50 max)
				newFaves.pop(); 
			}
			setFavorites(newFaves);

		} else {
			// Unmark from favorites		
			const newFaves = [...favorites];
			const filtered = newFaves.filter(e => !isEqual(e, exame.toObject()));
			setFavorites(filtered);

		}
		
		setIsSelectedExameFavorite(isEnabling);
	}, [favorites, setFavorites]);


	const selectEnsino = useCallback((ensino) => {
		setSelectedSection("download");
		setSelectedEnsino(ensino);

		if (!selectedEnsino) {
			// scroll into download panel when ensino is selected for the first time.
			sectionsContainerRef.current.scrollIntoView();
		}
		
		// Ensino Secundario // Ensino Superior
		// const tipoDeEnsino = ensino.substr(ensino.indexOf(" ") + 1); // will return everything after "Ensino "
		// history.push(`/ensino/${tipoDeEnsino.toLowerCase()}`);
		
	}, [selectedEnsino]);


	// Show the super dialog to download the selected exame.
	const showDownloadPrompt = useCallback((exame) => {
		setSelectedExame(exame); // update the selected exame to show in the dialog
		const exists = favorites.find(fe => isEqual(fe, exame.toObject()));
		setIsSelectedExameFavorite(exists ? true : false);

		setIsDownloadPromptOpen(true); // open the dialog
	}, [favorites]);

	// Just hide the super dialog.
	const hideDownloadPrompt = useCallback(() => {
		setSelectedExame({}); // clear selected exame
		setIsDownloadPromptOpen(false); // close the dialog
		
	}, []);

	
	// Whenever someone clicks on Enunciado or Guia download buttons.
	// Store it (as history) in browser's localStorage.
	const rememberDownload = useCallback((exame) => {
		const newRecents = [exame.toObject(), ...recents];
		const uniqueNewRecents = uniqWith(newRecents, isEqual); 
		// Remember up to 50 last downloaded exams
		if (uniqueNewRecents.length > 50) {
			// When we reach 50, for every new remembered exame,
			// remove the oldest one. (so we maintain in the 50 max)
			uniqueNewRecents.pop(); 
		}
		// update the localStorage
		setRecents(uniqueNewRecents);
		Logger.log("O seu download foi lembrado. Ao clicar no botão `Recentes` poderás ver todos os exames que foram lembrados.");
	}, [recents, setRecents]);
	


	/**
	 * Effects
	 */
	// On component mount, load ensinos types and a random citation.
	useEffect(() => {
		const _ensinosRaw = db.exames.map(exame => exame.ensino);
		setEnsinos(uniq(_ensinosRaw));

		// 💭 Choose a random citation from the database
		// And show it! 
		const citations = CitationsDatabase.citations;
		const randomCitationIndex = random(0, citations.length - 1);
		const _citation = citations[randomCitationIndex];
		setCitation(_citation);

	}, [db.exames]);


	useEffect(() => {
		if (location.pathname === "/recentes") {
			manuallyShowSection(true, "recentes");

		} else if (location.pathname === "/favoritos") {
			manuallyShowSection(true, "favoritos");

		} else if (location.pathname.includes("/e/Ensino Secundario")) {
			selectEnsino("Ensino Secundario");
		
		} else if (location.pathname.includes("/e/Ensino Superior")) {
			selectEnsino("Ensino Superior");
		
		}

		// } else {
		// 	history.push("/");
		// }
	}, [history, location.pathname, manuallyShowSection, selectEnsino]);



	/**
	 * Renderers
	 */
	const renderSection = useCallback(() => {
		if (selectedSection === "recentes") {
			return (
				<RecentsSection recents={recents}
					onOpen={showDownloadPrompt}
					onRemove={(recentExame) => {
						const newRecents = [...recents];
						const filtered = newRecents.filter(e => !isEqual(e, recentExame));
						setRecents(filtered);
						toast.success("Removido dos recentes");
					}}
					onClear={() => {
						// clear recents
						setRecents([]);
						toast.success("A sua lista de recentes foi limpada.");
					}} />
			);
		
		} else if (selectedSection === "download") {
			return (
				<DownloadSection exames={db.exames}
					onDownload={showDownloadPrompt}
					selectedEnsino={selectedEnsino} />
			);
		
		} else if (selectedSection === "favoritos") {
			return (
				<FavoritesSection favorites={favorites}
					onOpen={showDownloadPrompt}
					onClear={() => {
						// clear favorites
						setFavorites([]);
						toast.success("A sua lista de favoritos foi limpada.");
					}}
					onUnfavorite={(exame) => {
						// Unmark from favorites		
						const newFaves = [...favorites];
						const filtered = newFaves.filter(e => !isEqual(e, exame));
						setFavorites(filtered);
						toast.success("Desmarcado dos favoritos");
					}} />
			);

		} else {
			return (
				<div className="d-flex justify-content-center align-items-center p-3 p-lg-5">
					<div className="uk-animation-slide-top-medium">
						<h1>💭 <i className="fas fa-quote-left" style={{ color: "#594921" }}></i></h1>
						<h1 className="text-muted">{citation.quote}</h1>
						<h4 style={{ color: "#9C803A" }}>– {citation.author}</h4>
					</div>
				</div>
			);
		}

	}, [citation.author, citation.quote, db.exames, favorites, recents, selectedEnsino, selectedSection, setFavorites, setRecents, showDownloadPrompt]);


    return (
        <div>
			{/* Download selection */}
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-5 px-4 py-5 p-md-5 min-vh-100 bg-main">
						<div className="">
							<div>
								<img src={LogoImage} width="82" alt="Exames Moçambique Logo" />
							</div>
							<h3 className="mt-3">Exames Moçambique</h3>
							<div>
								Ola caro estudante.
								Estás a procura de exames nacionais para estudar?
								Estás na página certa! <span className="fst-italic"><u>Bons estudos</u>.</span>
							</div>

							<div className="my-4">
								<div className="mb-3">
									<a className="btn e-playstore text-decoration-none" target="_blank" href="https://play.google.com/store/apps/details?id=com.kishan.exames&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
										<div className="row gx-3 align-items-center">
											<div className="col-auto">
												<img width="32" src={PlayImage} alt="Google PlayStore Icon"/>
											</div>
											<div className="col-auto text-start">
												<small className="text-muted m-0">Disponível no</small><br/>
												Google Play
											</div>
										</div>
									</a>
								</div>

								<div>
									<Link to="/info" className="btn btn-dark rounded-pill text-decoration-none">
										<i className="fas fa-info-circle"></i> Informações
									</Link>
								</div>
							</div>

							<hr className="e-border"/>

							<h4 className="mt-4 mb-3 text-muted">Vamos estudar? Escolha:</h4>
							<div>
								<div className="row gy-2">
									<div className="col-auto">
										<button className={`btn fw-bold e-btn-toggle ${ selectedSection === "recentes" ? 'active' : '' }`}
											onClick={toggleRecentsBtn}>
											<i className="fas fa-history"></i> Recentes
										</button>
									</div>

									<div className="col-auto">
										<button className={`btn fw-bold e-btn-toggle ${ selectedSection === "favoritos" ? 'active' : '' }`}
											onClick={toggleFavoritesBtn}>
											<i className="fas fa-star"></i> Favoritos
										</button>
									</div>
								</div>

								<hr className="e-border" />

								{ ensinos.map(ensino => (
									<div key={ensino}
										tabIndex="0"
										onKeyPress={(e) => {
											if (e.key === " " || e.key === "Enter") {
												// if spacebar was hit
												selectEnsino(ensino);
												e.preventDefault(); // prevents the browser to scroll the page when spacebar is hit.
											}
										}}
										onClick={() => {
											history.push(`/e/${ensino}`);
											selectEnsino(ensino);
										}} 
										className={`e-tab-item ${selectedEnsino === ensino ? 'active' : ''} mb-3`}>
										
										<div className="row align-items-center">
											<div className="col-auto">
												<img title={ensino} src={ ensino === "Ensino Secundario" ? ENSEImage : ENSUImage } width="32px" alt={ensino}/>
											</div>
											<div className="col">
												<p className="mb-0 fw-bold">{ensino}</p>
											</div>
										</div>
									</div>
								)) }
							</div>

							<hr className="e-border"/>
							
							<div className="mb-5">
								<div>
									<small>
										© 2017-2021 Exames Moçambique e seus colaboradores individuais.
									</small>
								</div>
								<div>
									<small>
										Criado por <a style={{ color: "#564A27", fontWeight: 800 }} className=" text-decoration-none" href="https://kishanjadav.com">Kishan Jadav</a>
									</small>
								</div>
							</div>

						</div>
					</div>

					{/* Sections */}
					<div ref={sectionsContainerRef} id="sections" className={`col px-4 py-5 p-md-5 min-vh-100 bg-sections ${ selectedSection }`}>
						{ renderSection() }
					</div>
				</div>
			</div>


			{/* Download Prompt */}
			<SuperDialog exames={db.exames}
				exame={selectedExame}
				isFavorite={isSelectedExameFavorite}
				favorites={favorites}
				onClose={hideDownloadPrompt}
				onRemember={rememberDownload}
				onToggleFavorite={onFavorite}
				isOpen={isDownloadPromptOpen} />

		</div>
    );

};

export default Home;