import React from 'react';
import PremiumIcon from '../../images/premium.svg';
import NoAdIcon from '../../images/noad-icon.svg';
import NightmodeIcon from '../../images/nightmode-icon.svg';
import SettingsButtonImage from '../../images/settings.png';
import Nav from '../../components/Nav';
import { Link } from 'react-router-dom';

const Premium = () => {
    return (
        <div>
            <Nav />
            <section className="uk-section e-darkblue-gradient">
                <div className="uk-container uk-container-small uk-light uk-padding-small">
                    <img width="72" src={PremiumIcon} alt="Premium (O que é)" />
                    <h1 className="uk-margin-remove-bottom uk-text-bold uk-margin-small-top">Premium</h1>
                    <p>
                        Uma experiência sem anúncios, benefícios especiais e suporte ao Exames Moçambique. Quanto mais o Exames Moçambique é suportado pelo usuário, mais livres nós somos para tornar o Exames Moçambique o melhor possível.
                    </p>
                    <small>
                        <Link to="/info/premium/tc" className="text-reset uk-text-italic">– São aplicadas Termos e Condições de uso do premium para todos os assinantes.</Link>
                    </small>
                </div>
            </section>

            <section className="uk-section">
                <div className="uk-container uk-container-small uk-padding-small">
                    <div>
                        <h3>Introdução</h3>
                        <p><strong>Premium</strong> é uma forma de nos dar suporte e permitir que nós consigamos sustentar e manter o Exames Moçambique gratuíto e cada vez melhor. O valor pago pelo Premium, ajuda-nos a eliminar os custos altos dos nossos provedores de serviços, como o dos nossos servidores.</p>
                        <p>Estás a fim de nos ajudar também? Veja abaixo como, e quais as vantagens que pode lhe trazer.</p>
                    </div>

                    <div className="mt-5">
                        <h3>Benefícios Especiais<span className="text-primary">*</span></h3>
                        <div className="row">
                            <div className="col">
                                <div className="card p-5 border-0 rounded-0 shadow-sm">
                                    <img width="54" src={NoAdIcon} alt="" />
                                    <h5 className="mt-5">Anúncios Removidos</h5>
                                    <p>
                                        As publicidades mostradas ao longo do uso do app, serão todas removidas. Gostarias de saber o porquê da existência destes anúncios? <Link to="/info/ads">Leia mais informações sobre os anúncios</Link>.
                                    </p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card p-5 border-0 rounded-0 shadow-sm bg-dark">
                                    <img width="54" src={NightmodeIcon} alt="" />
                                    <h5 className="mt-5 text-light">Modo Nocturno</h5>
                                    <p className="text-light">
                                        O modo nocturno faz com que o app inverta a maioria das cores brancas do app para o escuro, permitindo uma navegação do app durante à noite melhor e saudáveis aos nossos olhos.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <small><span className="text-primary">*</span><i>Mais benefícios poderão ser adicionados em futuras actualizações do aplicativo.</i></small>
                    </div>

                    <div className="mt-5">
                        <h2>Como ativar o premium no seu aplicativo?</h2>
                        <p>Tornamos-lhe fácil para adquirí-lo:</p>
                        <span className="uk-badge bg-primary fw-bold">1</span> Abra o aplicativo.<br />
                        <span className="uk-badge bg-primary fw-bold">2</span> Entre em Definições. (<img src={SettingsButtonImage} width="36px" alt="Botão de definições no app" />)<br />
                        <span className="uk-badge bg-primary fw-bold">3</span> E por fim, selecione a opção Premium e siga as instruções.<br />

                        <div className="mt-3">
                            <small>
                                <strong>Nota:</strong> São aplicados os <Link to="/info/premium/tc">Termos e Condições de Uso</Link> e as <Link to="/info/privacy">Políticas de Privacidade</Link>.
                            </small>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Premium;