import React from 'react';
import './Nav.stylesheet.scss';
import BackToInfoIcon from '../images/back-to-info.svg';
import InlineLogoIcon from '../images/logo-inline.svg';
import { Link } from 'react-router-dom';

const Nav = () => {
    return (
        <nav className="navbar navbar-expand navbar-light bg-light">
            <div className="container">
                <div>
                    <Link to="/info" className="e-nav-back e-color-on-hover" href="#">
                        <img src={BackToInfoIcon} width="42" />
                    </Link>
                    <Link to="/" href="#">
                        <img src={InlineLogoIcon} width="42" />
                    </Link>
                </div>

                <div>
                    <Link to="/" className="btn btn-dark text-light rounded-pill">
                        Voltar ao Web App
                    </Link>
                </div>
            </div>
        </nav>
    );
};

export default Nav;
